<template>

<div class="question" :class="{'is-alert': alert}">

	<div class="filter-back" v-on:click="$emit('back')">&#8592; <span>Back</span></div>

	<com-info v-if="alert" :data="{text: alert, button: 'Proceed'}" v-on:answered="onAlertClick" />

	<template v-if="!alert">

		<app-title :text="data.title" :is-uppercase="true" :is-subtitle="true" />

		<div class="question-icon" :style="{backgroundImage: (alert) ? 'url()' : 'url(' + data.image + ')'}"></div>

		<div class="question-text">
			
			<p>{{ data.text }}</p>

		</div>

		<div class="question-buttons">

			<app-button v-for="button in data.buttons" :key="button.value" theme="purple" :text="button.text" v-on:click="onButtonClick(button.value, button.alert)" />

		</div>

	</template>

</div>

</template>

<script>

export default {

	props: ['data'],

	components: {
		'com-info': () => import('./Info')
	},

	data: function() {

		return {
			alert: false,
			value: false
		}

	},

	methods: {

		onAlertClick: function() {

			this.$emit('answered', this.value)

		},

		onButtonClick: function(value, alert) {

			this.$store.commit('answer', {
				name: this.data.name,
				value: value
			})

			if (alert) {

				this.alert = alert
				this.value = value

			} else {

				this.$emit('answered', value)

			}

		}

	}

}

</script>

<style scoped>

.question {
	max-width: 1000px;
	padding: 40px 10px;
	background-repeat: no-repeat;
	padding-right: 320px;
	background-size: 240px auto;
	background-position: 100% 60px;
}

.filter-back {
	position: absolute;
	left: 10px;
	top: 0px;
	color: #fff;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 16px;
	cursor: pointer;
}

.filter-back:hover span {
	text-decoration: underline;
}

.is-mobile .question {
	padding-right: 10px;
	padding-left: 10px;
	background-size: 240px auto;
	background-position: 50% 60px;
	padding-bottom: 20px;
}

.question.is-alert {
	padding-right: 0px;
}

.question-icon {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 240px;
	height: 240px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.is-mobile .question-icon {
	position: relative;
	top: auto;
	right: auto;
	width: 100%;
	height: 200px;
	margin: 20px 0px;
}

.question-text {
	color: #fff;
	font-size: 36px;
	line-height: 43px;
	margin-bottom: 40px;
}

.is-mobile .question-text {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 20px;
	text-align: center;
}

.question-text >>> p {
	margin-bottom: 40px;
}

.question-text >>> *:last-child {
	margin-bottom: 0px;
}

.is-mobile .question-text >>> p {
	margin-bottom: 20px;
}

.question-buttons {
	width: auto;
	display: inline-block;
}

.is-mobile .question-buttons {
	padding: 0px;
	width: 100%;
}

.question-buttons >>> .button {
	min-width: 170px;
	max-width: 360px;
	width: 100%;
	margin: 0px 0px 20px 0px;
}

.is-mobile .question-buttons >>> .button {
	text-align: center;
	margin: 0px 0px 10px 0px;
}

.question-buttons >>> .button:last-child {
	margin-bottom: 0px;
}

</style>
